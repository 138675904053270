<script setup>
import {CampaignState} from "~/common/Enums.ts";

const props = defineProps({
    campaign: Object,
});

const campaign = computed(() => {
    return props.campaign;
});

const isEvaluating = computed(() => {
    return campaign.value.state === CampaignState.EVALUATING;
});

</script>

<template>
    <div class="text-black/50 flex flex-wrap items-center gap-3">
        <BrandsIconAndName :theme="campaign?.advertiser?.theme"/>
        <span class="w-1 h-1 rounded-full bg-black/50"></span>
        <div v-if="isEvaluating">Evaluating</div>
        <CampaignDateInfo v-else-if="campaign.id" :campaign="campaign"/>
<!--        <span class="w-1 h-1 rounded-full bg-black/50"></span>-->
<!--        <span>-->
<!--            Relevance score: {{ campaign.relevanceScore }}%-->
<!--            <tippy to="parent">-->
<!--                Represents how good of a match is the campaign to your profile based on your past posts and audience.-->
<!--            </tippy>-->
<!--        </span>-->
    </div>
</template>